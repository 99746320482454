import { js2xml } from "xml-js";
import { convertPolishLetters } from "../../helpers/string";
import {useDataState} from "../../context/data.context";

type TransferCommon = {
  contractorName: string;
  title: string;
  paymentAccount: string;
  amount: string;
  currency: string;
  countryCode: string;
};

type CountryTransfer = TransferCommon & {
  type: "country";
};

type GlobalTransfer = TransferCommon & {
  type: "global";
  swift: string;
  bankBranch: string;
  costCover: string;
};

function useXMLGenerator(props: CountryTransfer[] | GlobalTransfer[]) {
  const state = useDataState();
    const { account } = state;

  const xmlData = {
    PRZELEWY: {
      PRZELEW: props.map((item) => {
        if (item.type === "country") {
          return {
            REFERENCJE: { _text: "" },
            RACH_OBC: { _text: account.PLN },
            BANK: { _text: "" },
            RACHUNEK: {
              _text: item.paymentAccount.replace(/PL|\s/g, ""),
            },
            DATA: { _text: "" },
            NAZWA: { _text: convertPolishLetters(item.contractorName) },
            KWOTA: { _text: item.amount },
            TRESC: { _text: convertPolishLetters(item.title) },
            WALUTA: { _text: item.currency },
            KRAJ: { _text: item.countryCode },
          };
        }

        return {
          REFERENCJE: { _text: "" },
          RACH_OBC: { _text: account.EUR },
          BANK: { _text: "" },
          RACHUNEK: { _text: item.paymentAccount.replace(/\s/g, "") },
          DATA: { _text: "" },
          NAZWA: { _text: convertPolishLetters(item.contractorName) },
          KWOTA: { _text: item.amount },
          TRESC: { _text: convertPolishLetters(item.title) },
          BANK_SWIFT: { _text: item.swift },
          BANK_ODDZ: { _text: item.bankBranch },
          KOSZTY_POKRYWA: { _text: item.costCover },
          WALUTA: { _text: item.currency },
          KRAJ: { _text: item.countryCode },
        };
      }),
    },
  };

  const xmlString = js2xml(xmlData, { compact: true, spaces: 2 });

  const handleDownload = (name: string) => {
    const blob = new Blob([xmlString], { type: "application/xml" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${name}.xml`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return { handleDownload, xmlData };
}

export { useXMLGenerator };
export type { GlobalTransfer, CountryTransfer };
