import { RecordType } from "src/types/data";
import { useDataDispatch } from "../context/data.context";

type DataIndexes = {
  [key: string]: number;
};

const dataIndexes: DataIndexes = {
  zgodny: 0,
  zatwierdzony: 1,
  skonto: 2,
  skonto_kto: 3,
  skonto_kiedy: 4,
  dokument: 5,
  tytul: 6,
  kontrahent_kod: 7,
  kontrahent_nazwa: 8,
  nip: 9,
  platnosc_konto: 10,
  platnosc_bank: 11,
  platnosc_swift: 12,
  kwota_netto: 13,
  kwota_brutto: 14,
  waluta: 15,
  "kwota PLN": 16,
  termin: 17,
  "data wystawienia": 18,
  "data otrzymania": 19,
  "data sprzedazy": 20,
  platnosc: 21,
  komentarz: 22,
  platnosc_komentarz: 23,
};

function useData() {
  const dispatchData = useDataDispatch();

  const handleData = (data: unknown[]) => {
    const transformedData: RecordType[] = [];

    const getCommentAmount = (comment: string | null) => {
      if (!comment) return null;

      const amountRegex = /(?:Kwota|kwota):\s*([\d\s,.]+)z[łl]/;

      const amountMatch = comment.match(amountRegex);
      const amountValue = amountMatch ? amountMatch[1].replace(/\s/g, "").replace(",", ".") : null;

      return amountValue;
    };
    const getCommentAccount = (comment: string | null) => {
      if (!comment) return null;

      const accountRegex = /konto:\s([\d\s]+)/i;
      const accountMatch = comment.match(accountRegex);
      const accountValue = accountMatch ? accountMatch[1] : null;

      return accountValue;
    };

    const handleBankBranch = (swift: string) => {
      if (swift && swift.length === 11) {
        return swift.substring(8);
      }

      return "XXX";
    };

    data.slice(1).map((row) => {
      const rowArray = row as string[];
      if (!row) return null;

      const record: RecordType = {
        dokument: rowArray[dataIndexes["dokument"]] as string,
        title: rowArray[dataIndexes["tytul"]] as string,
        contractorName: rowArray[dataIndexes["kontrahent_nazwa"]] as string,
        nip: rowArray[dataIndexes["nip"]] as string,
        iban: rowArray[dataIndexes["platnosc_konto"]].replace(/\s/g, "") as string,
        defaultPaymentAccount: rowArray[dataIndexes["platnosc_konto"]] as string,
        paymentSwift: rowArray[dataIndexes["platnosc_swift"]]
          ? rowArray[dataIndexes["platnosc_swift"]]
          : ("" as string),
        eurAmount: rowArray[dataIndexes["kwota_brutto"]] as string,
        plnAmount: getCommentAmount(rowArray[dataIndexes["platnosc_komentarz"]]) as string,
        plPaymentAccount: getCommentAccount(rowArray[dataIndexes["platnosc_komentarz"]]) as string,
        currency: rowArray[dataIndexes["waluta"]] as string,
        invoiceDateCreated: rowArray[dataIndexes["data wystawienia"]] as string,
        invoiceDateReceived: rowArray[dataIndexes["data otrzymania"]] as string,
        invoiceDateSold: rowArray[dataIndexes["data sprzedazy"]] as string,
        payment: rowArray[dataIndexes["platnosc"]] as string,
        commentPayment: rowArray[dataIndexes["platnosc_komentarz"]] as string,
        countryCode: rowArray[dataIndexes["platnosc_konto"]].slice(0, 2) as string,
        bank_branch: handleBankBranch(rowArray[dataIndexes["platnosc_swift"]]),
      };

      transformedData.push(record);
    });

    dispatchData({ type: "ADD_GLOBAL_DATA", data: transformedData });
  };

  const validateData = (data: unknown[]): null | string => {
    const error = null;
    const dataHeader = data[0] as string[];

    if (!dataHeader) {
      return "Nieprawidłowy plik. Brak nagłówka.";
    }

    //check if every column is in the right place in loop
    for (const key in dataIndexes) {
      if (dataHeader[dataIndexes[key]] !== key) {
        return `Zły układ kolumny: ${key}`;
      }
    }

    return error;
  };

  return { handleData, validateData };
}

export { useData };
