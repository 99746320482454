import { useDataDispatch, useDataState } from "src/context/data.context";
import styles from "./transfer-list.module.scss";
import React from "react";

const TransferList = () => {
  const state = useDataState();
  const dispatch = useDataDispatch();
  const { globalData, transferList, transferListHeader } = state;

  if (globalData.length === 0 || transferList.length === 0) {
    return null;
  }

  const handleCurrencySelection = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value as "PLN" | "default";

    dispatch({ type: "UPDATE_RECORD_CURRENCY", currency: value, index });
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Wszystkie przelewy({transferList.length})</h2>
      <table className={styles.table}>
        <thead>
          <tr>
            {transferListHeader.map((item) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {transferList.map((record, index) => (
            <tr key={index}>
              <td>{record.dokument}</td>
              <td>{record.title}</td>
              <td style={{ maxWidth: "200px", height: "50px" }}>{record.contractorName}</td>
              <td>
                <div>
                  <div>Konto domyślne: {record.paymentAccount.default}</div>
                  <div>Konto PL: {record.paymentAccount.pln ? record.paymentAccount.pln : "brak"}</div>
                </div>
              </td>
              <td>{record.swift}</td>
              <td className={styles.textCentered}>{record.countryCode}</td>
              <td>
                <form>
                  <div className={styles.optionWrapper}>
                    <input
                      type="radio"
                      onChange={(e) => handleCurrencySelection(e, index)}
                      value="default"
                      checked={record.amount.selected === "default"}
                    />
                    {record.currency === "EUR" ? <>EUR: {record.amount.default}&euro;</> : null}
                    {record.currency === "PLN" ? <>PLN: {record.amount.default}zł</> : null}
                  </div>
                  <div className={styles.optionWrapper}>
                    <input
                      type="radio"
                      onChange={(e) => handleCurrencySelection(e, index)}
                      value="PLN"
                      checked={record.amount.selected === "PLN"}
                      disabled={!record.paymentAccount.pln}
                    />
                    {record.amount.pln ? `PLN: ${record.amount.pln}zł ` : "brak"}
                  </div>
                </form>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { TransferList };
