import styles from "./select-account.module.scss";


import {useDataDispatch, useDataState} from "src/context/data.context";
import {useState} from "react";

export type Account = {
    PLN: string;
    EUR: string;
};

type Option = {
    name: string;
    account: Account;
};

const ORION_PLN_ACCOUNT = "88109014630000000148023066" as const;
const ORION_EUR_ACCOUNT = "82109014630000000148023077" as const;
const HUNTERS_PLN_ACCOUNT = "07109014630000000155205628" as const;
const HUNTERS_EUR_ACCOUNT = "15109014630000000155205678" as const;

const accountOptions: Option[] = [
    {
        name: "Orion",
        account: {
            PLN: ORION_PLN_ACCOUNT,
            EUR: ORION_EUR_ACCOUNT,
        }
    },
    {
        name: "Order Hunters",
        account: {
            PLN: HUNTERS_PLN_ACCOUNT,
            EUR: HUNTERS_EUR_ACCOUNT
        }
    }
];

export const defaultAccount = accountOptions[0];

const SelectAccount = () => {
    const state = useDataState();
    const dispatch = useDataDispatch();
    const { globalData, transferList } = state;

    const [selectedOption, setSelectedOption] = useState(defaultAccount.name);

    if (globalData.length === 0 || transferList.length === 0) {
        return null;
    }

    const handleChange = (event:any) => {
        const selectedOption = accountOptions.find(option => option.name === event.target.value);

        if (selectedOption) {
            setSelectedOption(selectedOption.name);

            dispatch({
                type: "SET_ACCOUNT",
                account: selectedOption.account
            });
        }
    };

    return (
        <div className={styles.wrapper}>
            <h2>Wybierz konto</h2>

            <select id="select" value={selectedOption} onChange={handleChange} className={styles.select}>
                {accountOptions.map((option, index) => (
                    <option key={index} value={option.name}>
                        {option.name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export { SelectAccount };
