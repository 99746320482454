import React, { useContext, useReducer } from "react";
import { RecordType } from "src/types/data";
import {Account, defaultAccount} from "../components/select-account/select-account.component";

type State = {
  globalData: RecordType[];
  transferListHeader: string[];
  transferList: Array<{
    dokument: string;
    title: string;
    contractorName: string;
    paymentAccount: {
      default: string;
      pln: string;
    };
    countryCode: string;
    amount: {
      default: string;
      pln: string | null;
      selected: "PLN" | "default";
    };
    iban: string;
    swift: string;
    bankBranch: string;
    currency: string;
  }>;
  account: Account;
};

type Action =
  | {
      type: "ADD_GLOBAL_DATA";
      data: RecordType[];
    }
  | {
      type: "UPDATE_RECORD_CURRENCY";
      index: number;
      currency: "PLN" | "default";
    }
    | {
        type: "SET_ACCOUNT";
        account: Account;
        };

type Dispatch = (action: Action) => void;

export const DataStateContext = React.createContext<State | undefined>(undefined);

export const DataDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "ADD_GLOBAL_DATA": {
      return {
        ...state,
        globalData: action.data,
        transferList: action.data.map((record) => ({
          dokument: record.dokument,
          title: record.title,
          contractorName: record.contractorName,
          paymentAccount: {
            default: record.defaultPaymentAccount,
            pln: record.plPaymentAccount,
          },
          countryCode: record.countryCode,
          amount: {
            default: record.eurAmount,
            pln: record.plnAmount,
            selected: record.countryCode === "PL" && record.plPaymentAccount ? "PLN" : "default",
          },
          iban: record.iban,
          swift: record.paymentSwift,
          bankBranch: record.bank_branch,
          currency: record.currency,
        })),
      };
    }

    case "UPDATE_RECORD_CURRENCY": {
      return {
        ...state,
        transferList: state.transferList.map((record, index) => {
          if (index === action.index) {
            return {
              ...record,
              amount: {
                ...record.amount,
                selected: action.currency,
              },
            };
          }
          return record;
        }),
      };
    }

    case "SET_ACCOUNT": {
      return {
        ...state,
        account: action.account,
      };
    }

    default:
      throw new Error();
  }
}

type DataProviderProps = {
  children: React.ReactNode;
};

const DataProvider = ({ children }: DataProviderProps) => {
  const defaultState: State = {
    globalData: [],
    transferListHeader: ["Dokument", "Tytuł", "Nazwa kontrahenta", "Konta płatnika", "Swift", "Kraj", "Kwota"],
    transferList: [],
    account: defaultAccount.account
  };

  const [state, dispatch] = useReducer(reducer, defaultState);

  return (
    <DataStateContext.Provider value={state}>
      <DataDispatchContext.Provider value={dispatch}>{children}</DataDispatchContext.Provider>
    </DataStateContext.Provider>
  );
};

function useDataState() {
  const context = useContext(DataStateContext);
  if (context === undefined) {
    throw new Error("useDataState must be used within a DataProvider");
  }
  return context;
}

function useDataDispatch() {
  const context = useContext(DataDispatchContext);
  if (context === undefined) {
    throw new Error("useDataDispatch must be used within a DataProvider");
  }
  return context;
}

export { DataProvider, useDataState, useDataDispatch };
